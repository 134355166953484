import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { TabPageAbstractComponent } from '@components/organisms/tab-page-module/tab-page-abstract/tab-page-abstract.component';
import {
  VehicleOrderApiProps,
  VehicleOrderProps,
  VehicleOrderSection
} from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import { Observable } from 'rxjs';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';

@Component({
  selector: 'vehicle-order-details',
  templateUrl: './vehicle-order-details.component.html',
  styleUrl: './vehicle-order-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderDetailsComponent extends TabPageAbstractComponent<VehicleOrderSection> implements OnInit {
  @Input() isTaskBoard = false;
  details$: Observable<any>;
  protected readonly VehicleOrderConstant = VehicleOrderConstant;
  protected readonly VehicleOrderApiProps = VehicleOrderApiProps;
  protected readonly VehicleOrderProps = VehicleOrderProps;

  constructor(
    private $injector: Injector,
    private _vehicleOrderService: VehicleOrderService
  ) {
    super($injector);
  }

  ngOnInit(): void {
    this._setEntityConfig();
    this._getVehicleOrders();
    super.initialize();
  }

  private _setEntityConfig() {
    this.sectionList = VehicleOrderConstant.formSectionList;
    this.sectionConfig = VehicleOrderConstant.formSectionConfig;
  }

  private _getVehicleOrders() {
    this.details$ = this._vehicleOrderService.getVehicleOrder(this.id);
  }
}
