import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VehicleOrder } from '@pages/new-business/pages/vehicle-order/types/vehicle-order.type';
import { ModifyOrderProps, VehicleOrderProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import { Observable } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfigProps } from '@utils/enums/forms.enum';

@Component({
  selector: 'vehicle-order-bulk-modify',
  templateUrl: './vehicle-order-bulk-modify.component.html',
  styleUrl: './vehicle-order-bulk-modify.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleOrderBulkModifyComponent implements OnInit {
  @Input() vehicleOrderList: VehicleOrder[];
  @Input() isOpen: boolean;
  @Input() distributorList: any;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;
 
  productLineList: any;
  protected readonly VehicleOrderProps = VehicleOrderProps;
  protected readonly VehicleOrderConstant = VehicleOrderConstant;

  constructor(
    private _distributorManagerService: DistributorManagerService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
  }

  handleSubmit() {
    this.cancel.emit();
  }

  private _initializeFormConfig() {
    this._distributorManagerService.getproductLinesTypes().subscribe(result => {
      this.productLineList = this._distributorManagerService.createDropdownProductLinesTypes(result)
    });
    const groupConfig = NextFormHelper.buildGroupValuesWithConfig(
      [ModifyOrderProps.TARGET_DISTRIBUTOR, ModifyOrderProps.TARGET_PRODUCT_LINE],
      {},
      {
        [ModifyOrderProps.TARGET_DISTRIBUTOR]: {
          [FormConfigProps.IS_REQUIRED]: true
        },
        [ModifyOrderProps.TARGET_PRODUCT_LINE]: {
          [FormConfigProps.IS_REQUIRED]: true
        }
      }
    );

    this.formGroup = this._fb.group(groupConfig);
  }

  protected readonly ModifyOrderProps = ModifyOrderProps;
}
