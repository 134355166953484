import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VehicleOrder, VehicleOrderTotal } from '@pages/new-business/pages/vehicle-order/types/vehicle-order.type';
import { GenericObject } from '@utils/models/Types';
import {
  VehicleOrderProps,
  VehicleOrderTotalProps
} from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';

@Component({
  selector: 'vehicle-order-totals',
  templateUrl: './vehicle-order-totals.component.html',
  styleUrl: './vehicle-order-totals.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderTotalsComponent implements OnChanges {
  @Input() vehicleOrderList: VehicleOrder[];

  totalList: VehicleOrderTotal[];
  protected readonly VehicleOrderTotalProps = VehicleOrderTotalProps;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vehicleOrderList']) {
      this._calculateTotals();
    }
  }

  private _calculateTotals() {
    const totals: GenericObject = {};
    this.vehicleOrderList.forEach((vehicleOrder: VehicleOrder) => {
      if (totals[vehicleOrder[VehicleOrderProps.ORDER_CURRENCY]]) {
        totals[vehicleOrder[VehicleOrderProps.ORDER_CURRENCY]][VehicleOrderTotalProps.COUNT]++;
        totals[vehicleOrder[VehicleOrderProps.ORDER_CURRENCY]][VehicleOrderTotalProps.TOTAL] += Math.round(
          vehicleOrder[VehicleOrderProps.ORDER_AMOUNT]
        );
      } else {
        totals[vehicleOrder[VehicleOrderProps.ORDER_CURRENCY]] = {
          [VehicleOrderTotalProps.COUNT]: 1,
          [VehicleOrderTotalProps.TOTAL]: vehicleOrder[VehicleOrderProps.ORDER_AMOUNT],
          [VehicleOrderTotalProps.CURRENCY]: vehicleOrder[VehicleOrderProps.ORDER_CURRENCY]
        };
      }
    });

    this.totalList = Object.keys(totals).map(currencyKey => {
      return totals[currencyKey];
    });
  }
}
