import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import {
  VehicleOrderAction,
  VehicleOrderManagerTestIds
} from '@pages/new-business/pages/vehicle-order/pages/vehicle-order-manager/enums/vehicle-order-manager.enum';
import { VehicleOrder } from '@pages/new-business/pages/vehicle-order/types/vehicle-order.type';
import { TableFilterEvent, TablePageEvent } from 'primeng/table';
import { FilterBaseComponent } from '@components/organisms/next-table/components/filter-base/filter-base.component';
import { Observable, map } from 'rxjs';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';
import { VehicleOrderProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { GenericTableColumnProps, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { MenuItemService } from 'app/core/services/menu-item.service';
import { NavigationService } from '@services/navigation.service';
import { RouteParams } from 'app/core/enums/route.enum';
import { VehicleOrderRouteConfig } from '../../vehicle-order.route.config';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NavigationConfigProps } from '../../../../../../shared/enums/navigation.enum';
import { DistributorService } from '@pages/distributor/services/distributor.service';

@Component({
  selector: 'vehicle-order-manager',
  templateUrl: './vehicle-order-manager.component.html',
  styleUrl: './vehicle-order-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderManagerComponent extends FilterBaseComponent implements OnInit {
  vehicleOrderList$: Observable<VehicleOrder[]>;
  selectedOrders: VehicleOrder[];
  isBulkModifyOpen: boolean;
  filteredValues: VehicleOrder[];
  distributorList: any;
  
  protected readonly VehicleOrderConstant = VehicleOrderConstant;
  protected readonly VehicleOrderManagerTestIds = VehicleOrderManagerTestIds;
  protected readonly GenericTableColumnProps = GenericTableColumnProps;
  protected readonly TableFieldProps = TableFieldProps;
  protected readonly VehicleOrderProps = VehicleOrderProps;

  constructor(
    injector$: Injector,
    private _vehicleOrderService: VehicleOrderService,
    private _menuItemService: MenuItemService,
    private _navigationService: NavigationService,
    private _cdr: ChangeDetectorRef,
    private _distributorService: DistributorService,
  ) {
    super(injector$);
    this._cdr = injector$.get(ChangeDetectorRef);
  }

  ngOnInit() {
    this.applyFilter();
    this._distributorService.getList().subscribe(result => {
      this.distributorList = this._distributorService.getDropdownList(result)
    });
    this.vehicleOrderList$ = this._vehicleOrderService.getOrders().pipe(
      map((vehicleOrderList: VehicleOrder[]) => {
        return vehicleOrderList.map((vehicleOrder: VehicleOrder) => {
          return {
            ...vehicleOrder,
            [GenericTableColumnProps.MENU_LIST]: this._menuItemService.buildMenuItemList(
              [VehicleOrderAction.MODIFY, VehicleOrderAction.CANCEL],
              {
                [VehicleOrderAction.MODIFY]: {
                  ...VehicleOrderConstant.actionConfig[VehicleOrderAction.MODIFY],
                  callback: () => this._modifyActionCallback(vehicleOrder)
                },
                [VehicleOrderAction.CANCEL]: {
                  ...VehicleOrderConstant.actionConfig[VehicleOrderAction.CANCEL]
                }
              }
            )
          };
        });
      })
    );
    this._cdr.markForCheck();
  }

  handleModifyTask(vehicleOrder: VehicleOrder) {
    this._navigationService
      .navigateToRouteConfig(VehicleOrderRouteConfig.newVehicleOrderFormId, {
        urlParams: { [RouteParams.ID]: vehicleOrder.id }
      })
      .then();
  }

  pageChangeHandle(event: TablePageEvent) {
    this.handlePaginationChange(event);
  }

  sortPageChangeHandle(event: any) {
    // TODO Implement
  }

  viewOrderDetails(order: VehicleOrder) {
    this._navigationService
      .navigateToRouteConfig(VehicleOrderRouteConfig.detailsId, {
        [NavigationConfigProps.URL_PARAMS]: { [RouteParams.ID]: order[VehicleOrderProps.ID] },
        [NavigationConfigProps.ROUTE_PARENT]: VehicleOrderRouteConfig.mainRoute
      })
      .then();
  }

  handleFilterValueChange(tableEvent: TableFilterEvent) {
    this.filteredValues = NextValueHelper.defaultValue(tableEvent.filteredValue, []);
  }

  handleModifyVehicleOrder() {
    this.isBulkModifyOpen = true;
  }

  handleCancelBulkModify() {
    this.isBulkModifyOpen = false;
  }

  handleCancelVehicleOrderSelection() {
    this.selectedOrders = [];
  }

  private _modifyActionCallback(vehicleOrder: VehicleOrder) {
    this.handleModifyTask(vehicleOrder);
  }
}
