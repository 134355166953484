@if (selection && selection.length) {
  <bulk-actions-ribbon
    [actionLabelKey]="'VEHICLE_ORDER.ACTIONS.MODIFY_VEHICLE_ORDER'"
    (actionSubmit)="modifyAction.emit($event)"
    (actionCancel)="cancelAction.emit($event)"
  ></bulk-actions-ribbon>
} @else {
  <next-table-filter
    [extraFormGroup]="extraFormGroup"
    [filterValues]="filterValues"
    [placeholderInputSearch]="'VEHICLE_ORDER.FILTER.PLACEHOLDER'"
    (filterChange)="filterChange.emit($event)"
    [hasExtraFilter]="hasExtraFilter"
  >
    <div [formGroup]="extraFormGroup" class="vehicle-order-filter__form" extraFilterContent>
      <div class="vehicle-order-filter__row">
        <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.DISTRIBUTOR_ID] as distributorControl"
          [control]="distributorControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.DISTRIBUTOR'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="distributorControl"
            [items]="distributorList"
            [name]="VehicleOrderFilterProps.DISTRIBUTOR_ID"
            inputElement
          ></next-dropdown>
        </input-container>

        <input-container
          *ngIf="extraFormGroup"
          [labelKey]="'VEHICLE_ORDER.FILTER.ORDER_DATE'"
          class="vehicle-order-filter__item"
        >
          <date-from-to
            [fromControl]="extraFormGroup | deepControl: [VehicleOrderFilterProps.ORDER_DATE_FROM]"
            [toControl]="extraFormGroup | deepControl: [VehicleOrderFilterProps.ORDER_DATE_TO]"
            inputElement
          ></date-from-to>
        </input-container>

        <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.PRODUCT_LINE] as productLineControl"
          [control]="productLineControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.PRODUCT_LINE'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="productLineControl"
            [items]="productLineList"
            [name]="VehicleOrderFilterProps.PRODUCT_LINE"
            inputElement
          ></next-dropdown>
        </input-container>

        <!-- <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.SOURCE_LOCATION] as sourceLocationControl"
          [control]="sourceLocationControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.SOURCE_LOCATION'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="sourceLocationControl"
            [itemsObs$]="sourceLocationList$"
            [name]="VehicleOrderFilterProps.SOURCE_LOCATION"
            inputElement
          ></next-dropdown>
        </input-container> -->

        
      </div>
      <div class="vehicle-order-filter__row">
        <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.VEHICLE_MODEL] as vehicleModelControl"
          [control]="vehicleModelControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.VEHICLE_MODEL'"
          class="vehicle-order-filter__item"
        >
          <next-input
            [control]="vehicleModelControl"
            [name]="VehicleOrderFilterProps.VEHICLE_MODEL"
            [testIdDataAttr]="VehicleOrderFilterProps.VEHICLE_MODEL"
            [placeholder]="'VEHICLE_ORDER.FILTER.VEHICLE_MODEL_SEARCH' |translate"
            inputElement
          ></next-input>
        </input-container>
        <!-- <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.ORDER_ID] as orderNumberControl"
          [control]="orderNumberControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.ORDER_NUMBER'"
          class="vehicle-order-filter__item"
        >
          <next-input
            [control]="orderNumberControl"
            [name]="VehicleOrderFilterProps.ORDER_ID"
            [testIdDataAttr]="VehicleOrderFilterProps.ORDER_ID"
            [placeholder]="'VEHICLE_ORDER.FILTER.ORDER_NUMBER_SEARCH' |translate"
            inputElement
          ></next-input>
        </input-container> -->
        <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.ORDER_CURRENCY] as currencyControl"
          [control]="currencyControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.CURRENCY'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="currencyControl"
            [itemsObs$]="currencyList$"
            [name]="VehicleOrderFilterProps.ORDER_CURRENCY"
            inputElement
          ></next-dropdown>
        </input-container>

        <!-- <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.ORDER_STATUS] as orderStatusControl"
          [control]="orderStatusControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.ORDER_STATUS'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="orderStatusControl"
            [itemsObs$]="orderStatusList$"
            [name]="VehicleOrderFilterProps.ORDER_STATUS"
            inputElement
          ></next-dropdown>
        </input-container> -->

        <!-- <input-container
          *ngIf="extraFormGroup | deepControl: [VehicleOrderFilterProps.CAM] as camControl"
          [control]="camControl"
          [labelKey]="'VEHICLE_ORDER.FILTER.CAM'"
          class="vehicle-order-filter__item"
        >
          <next-dropdown
            [control]="camControl"
            [itemsObs$]="camList$"
            [name]="VehicleOrderFilterProps.CAM"
            inputElement
          ></next-dropdown>
        </input-container> -->
      </div>
    </div>
  </next-table-filter>
}
