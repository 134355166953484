import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VehicleOrder } from '@pages/new-business/pages/vehicle-order/types/vehicle-order.type';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';

@Component({
  selector: 'vehicle-order-details-general-information',
  templateUrl: './vehicle-order-details-general-information.component.html',
  styleUrl: './vehicle-order-details-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOrderDetailsGeneralInformationComponent {
  @Input() data: VehicleOrder;
  protected readonly VehicleOrderConstant = VehicleOrderConstant;
}
