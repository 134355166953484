import { Routes } from '@angular/router';
import { VehicleOrderRouteConfig } from '@pages/new-business/pages/vehicle-order/vehicle-order.route.config';
import { NextRouteProps } from '../../../../core/enums/route.enum';
import { authGuardFn } from '../../../../shared/guards/auth.guard';
import { VehicleOrderManagerComponent } from '@pages/new-business/pages/vehicle-order/pages/vehicle-order-manager/vehicle-order-manager.component';
import { RoutingConfigConstant } from 'app/core/constants/routing-config.constant';
import { VehicleOrderConstant } from './constants/vehicle-order.constant';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { dirtyFormGuard } from '@pages/distributor/guards/distributor-manager.guard';
import { VehicleOrderSection } from './enums/vehicle-order.enum';
import { VehicleOrderFormComponent } from '@pages/new-business/pages/vehicle-order/pages/vehicle-order-edition/components/vehicle-order-form/vehicle-order-form.component';
import { VehicleOrderDetailsComponent } from '@pages/new-business/pages/vehicle-order/pages/vehicle-order-details/vehicle-order-details.component';

export class VehicleOrderRouting {
  static routingConfig: Routes = [
    {
      path: '',
      children: [
        {
          path: '',
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.mainRoute
          },
          component: VehicleOrderManagerComponent
        },
        {
          path: `${VehicleOrderRouteConfig.newVehicleOrderConfig[NextRouteProps.PATH]}`,
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.newVehicleOrderConfig
          },
          children: [
            {
              path: '',
              redirectTo: `${RoutingConfigConstant.newParam}`,
              pathMatch: 'full'
            },
            {
              path: VehicleOrderRouteConfig.newVehicleOrderFormId[NextRouteProps.PATH],
              data: { [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.newVehicleOrderFormId },
              children: [
                {
                  path: '',
                  redirectTo: `${VehicleOrderConstant.formSectionConfig[VehicleOrderSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
                  pathMatch: 'full'
                },
                {
                  path: VehicleOrderRouteConfig.vehicleOrderFormStep[NextRouteProps.PATH],
                  canDeactivate: [dirtyFormGuard],
                  component: VehicleOrderFormComponent,
                  data: {
                    [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.vehicleOrderFormStep,
                    [NextRouteProps.FORM_SECTION_CONFIG]: VehicleOrderConstant.formSectionConfig
                  }
                }
              ]
            }
          ]
        },
        {
          path: VehicleOrderRouteConfig.detailsConfig[NextRouteProps.PATH],
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.detailsConfig
          },
          children: [
            {
              path: VehicleOrderRouteConfig.detailsId[NextRouteProps.PATH],
              data: {
                [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.detailsId
              },
              children: [
                {
                  path: '',
                  redirectTo: `${VehicleOrderConstant.formSectionConfig[VehicleOrderSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
                  pathMatch: 'full'
                },
                {
                  path: VehicleOrderRouteConfig.detailsTabStep[NextRouteProps.PATH],
                  component: VehicleOrderDetailsComponent,
                  data: {
                    [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.detailsTabStep,
                    [NextRouteProps.FORM_SECTION_CONFIG]: VehicleOrderConstant.formSectionConfig
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  static entry = {
    path: VehicleOrderRouteConfig.mainRoute.path,
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.mainRoute
    },
    loadChildren: () => import('./vehicle-order.module').then(m => m.VehicleOrderModule),
    canActivate: [authGuardFn]
  };
}
