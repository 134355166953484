<ng-container *ngIf="(this.details$ | async) as details">
  <next-action-header
    [labelKey]="'VEHICLE_ORDER.TITLE'"
  >
  </next-action-header>
  <general-details
    [header]="'VEHICLE_ORDER.FIELDS.ORDER_NUMBER_ID' | translate: {id}"
    [dataTitleConfig]="VehicleOrderConstant.generalDetailsMap"
    [data]="details "
    [status]="details | safeGuardObjectProperty: [VehicleOrderProps.ORDER_STATUS]"
  ></general-details>
  <p-tabView [activeIndex]="sectionList.indexOf(activeSectionSig())" (activeIndexChange)="handleChangeTab($event)">
    <p-tabPanel [header]="'VEHICLE_ORDER.SECTION.GENERAL_INFORMATION.TITLE' | translate">
      <vehicle-order-details-general-information [data]="details"></vehicle-order-details-general-information>
    </p-tabPanel>
  </p-tabView>
</ng-container>
