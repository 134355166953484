<div class="vehicle-order-totals">
  <div class="vehicle-order-totals__section-container">
    <next-card [hasBorder]="true">
      <div class="vehicle-order-totals__container">
        <span class="vehicle-order-totals__container-title">
          {{ 'VEHICLE_ORDER.TOTALS.ITEMS' | translate }}
        </span>
        <span *ngFor="let total of totalList">
          {{ total | safeGuardObjectProperty: [VehicleOrderTotalProps.CURRENCY] }}
          : {{ total | safeGuardObjectProperty: [VehicleOrderTotalProps.COUNT] }}
        </span>
      </div>
    </next-card>
  </div>
  <div class="vehicle-order-totals__section-container">
    <next-card [hasBorder]="true"></next-card>
  </div>
  <div class="vehicle-order-totals__section-container">
    <next-card [hasBorder]="true">
      <div class="vehicle-order-totals__container">
        <span class="vehicle-order-totals__container-title">
          {{ 'VEHICLE_ORDER.TOTALS.VALUE_TOTAL' | translate }}
        </span>
        <span *ngFor="let total of totalList">
          {{
          total
            | safeGuardObjectProperty: [VehicleOrderTotalProps.TOTAL]
            | nextFormatCurrency: total[VehicleOrderTotalProps.CURRENCY]
          }}
        </span>
      </div>
    </next-card>
  </div>
  <div class="vehicle-order-totals__section-container">
    <next-card [hasBorder]="true"></next-card>
  </div>
</div>
